import React from "react"
import Header from "../components/home/header"
import Aside from "../components/home/sider"
import Updates from "../components/home/updates"
import Contact from "../components/home/contact"
import Footer from "../components/home/footer"
import FooterImg from "../components/home/footerImg"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styles from "./styles.module.scss"

export const query = graphql`
  query HomePageQueryEn {
    allContentfulMainPage(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          header
          headerImage {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          aboutUsButtonText
          lawyersTitle
          lawyers {
            avatar {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            name
            description
          }
          updatesTitle
          updates {
            title
            summary
            more
            slug
            contentful_id
          }
          contactTitle
          contactMailPlaceholder
          contentNamePlaceholder
          contactPhonePlaceholder
          location
          locationTitle
          submitButtonText
          general {
            logo {
              file {
                url
              }
            }
            contactEmail
            contactPhone
          }
        }
      }
    }
  }
`

const IndexPage = ({ data, location }) => {
  const res = data.allContentfulMainPage.edges[0].node

  return (
    <>
      <section className={styles.container}>
        <SEO description="Black Sea Law Group" lang="en" title="Home" />
        <Header lang="en" location={location.pathname} data={res} />
        <Aside lang="en" />
      </section>
      <section className={styles.updates__container}>
        <Updates lang="en" data={res} />
        <Contact
          namePlaceholder={res.contentNamePlaceholder}
          mailPlaceholder={res.contactMailPlaceholder}
          phonePlaceholder={res.contactPhonePlaceholder}
          data={res}
        />
      </section>
      <section className={styles.footer__wrapper}>
        <section className={styles.footer__image}>
          <FooterImg />
        </section>
        <section className={styles.footer__container}>
          <Footer data={res} />
        </section>
      </section>
    </>
  )
}

export default IndexPage
